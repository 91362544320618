
  export default {
    name: `AeqDateField`,
    props: {
      type: {
        type: String,
        default: `date`
      },
      errorMessages: {
        type: [String, Array],
        default: () => []
      },
      value: {
        type: String,
        default: ``
      },
      extendButton: {
        type: Boolean,
        default: false
      },
      variation: {
        type: Array,
        default: null
      },
      variationLabel: {
        type: String,
        default: ``
      },
      changeDate: {
        type: Function,
        default: () => {}
      }
    },
    data() {
      return {
        datePickerProps: {},
        textFieldProps: {},
        formats: {
          date: `DD.MM.YYYY`,
          month_from: `MM.YYYY`,
          month_to: `MM.YYYY`
        },
        dirtyInnerValue: ``,
        valueDatePicker: null,
        innerValue: this.value,
        dateFormat: (value) => this.$moment(value, this.formats[this.type]).isValid() || !value,
        menu: false,
        trueFormat: false
      }
    },
    computed: {
      traductedErrors() {
        return this.errorMessages.map((e) => this.$t(e))
      },
      currentLocale() {
        return this.locale || this.$vuetify.lang.current
      }
    },
    watch: {
      dirtyInnerValue(newValue) {
        this.getInput(newValue)
      },
      innerValue(newValue) {
        this.valueDatePicker = this.trueFormat ? newValue : null
      }
    },
    mounted() {
      if (!!this.value) {
        this.getInput(this.value)
      }
    },
    methods: {
      _changeDate(value) {
        const newDate = this.changeDate(value)
        this.getInput(newDate)
        this.menu = false
      },
      getInput(value) {
        let date = null
        switch (this.type) {
          case `month_from`:
            date = this.$moment(value, [`YYYY-MM`, `YYYY-MM-DD`, `MM.YYYY`, `DD.MM.YYYY`, `M.YYYY`, `D.M.YYYY`], true)
            date.date(1)
            break
          case `month_to`:
            date = this.$moment(value, [`YYYY-MM`, `YYYY-MM-DD`, `MM.YYYY`, `DD.MM.YYYY`, `M.YYYY`, `D.M.YYYY`], true)
            date.date(date.endOf(`month`).date())
            break
          case `date`:
          default:
            date = this.$moment(value, [`YYYY-MM-DD`, `DD.MM.YYYY`, `D.M.YYYY`], true)
        }

        if (!!date && date.isValid()) {
          this.innerValue = date.format(`YYYY-MM-DD`)
          this.dirtyInnerValue = date.format(this.formats[this.type])
          this.menu = false
          this.trueFormat = true
        } else {
          this.innerValue = value
          this.trueFormat = false
        }

        this.$emit(`input`, this.innerValue)
        this.$emit(`blur`, this.innerValue)
      }
    }
  }
